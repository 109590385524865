header {
    position: relative;
    z-index: 10;
    box-shadow: 3px 3px 5px var(--color-shd-1);
    
    >.container {
        display: grid;
        grid-template-columns: 15% 70% 15%;
        grid-template-rows: unset;
        padding: 1em 0.5em;
        background-color: var(--color-bg-2);
        position: relative;

        >.logo {
            display: flex;
            align-items: center;
            justify-content: center;
            grid-column: 2;

            >a>img {
                width: 100%;
            }
        }

        >.pesquisa {
            grid-column: 3;
            grid-row: 1;
            display: flex;
            align-items: center;

            >a {
                height: 100%;
                display: contents;

                >p {
                    display: none;
                }

                .icon {
                    display: block;
                    width: 60%;
                    height: 50%;
                    background-image: var(--img-pesquisa1);
                }
            }
        }

        >.responsive-menu {
            grid-column: 1;
            grid-row: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            >.menu-open {
                display: none;
            }

            >.menu-open-button {
                width: 70%;
                height: 70%;
                display: flex;
                justify-content: center;
                align-items: center;


                span,
                span::before,
                span::after {
                    display: block;
                    content: '';
                    width: 2em;
                    height: .5em;
                    transition-duration: 180ms;
                }

                >span {
                    display: flex;
                    background-color: var(--color-orn-4);
                }

                >span::before {
                    margin-top: -.7em;
                    background-color: var(--color-orn-3);
                }

                >span::after {
                    margin-top: .7em;
                    background-color: var(--color-orn-5);
                }
            }
        }

        >.responsive-menu:has(.menu-open:checked) {
            >.menu-open-button {

                span,
                span::before,
                span::after {
                    width: 2em;
                    background-color: var(--color-orn-6);
                }

                >span {
                    display: block;
                    transform: rotate(45deg);
                }

                >span::after {
                    margin-top: 0;
                    background-color: transparent;
                }

                >span::before {
                    margin-top: 0;
                    transform: rotate(-90deg);
                }
            }
        }

        >.menu button,
        a {
            text-decoration: none;
            border: none;
            color: var(--color-txt-1);
            background-color: transparent;
            display: block;
        }

        >.menu {
            grid-column: 1/4;
            grid-row: 2;
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: auto auto;
            height: 0em;
            overflow: hidden;
            transition-duration: 180ms;

            >.links {
                display: none;
            }

            .acessibilidade {
                grid-row: 2;
                display: flex;
                border-top: none;
                justify-content: center;
                gap: 16px;
                padding: 20px 0;
                background-color: var(--color-bg-4);
                border: 2px solid var(--color-orn-1);

                >button {
                    width: max-content;

                    >i {
                        display: block;
                        width: 20px;
                        height: 20px;
                        background-image: var(--img-contrates1);
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                }
            }

            >nav ul {
                list-style: none;
                padding: 0;
                margin: 0;
            }

            >nav button {
                font-weight: bold;
            }

            >nav {
                display: flex;
                height: 100%;
                width: 100%;

                >.menu {
                    display: flex;
                    overflow: hidden;
                    width: 0;
                    height: 0;
                }

                >.overflow-menu {
                    width: 100%;

                    >.item-menu {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        >.menu-open {
                            display: none;
                        }

                        >.submenu {
                            background-color: var(--color-bg-2);
                            width: 100%;

                            >.item-menu {
                                display: flex;
                                flex-direction: column;
                                border: solid 2px var(--color-orn-3);
                                border-bottom: solid 2px var(--color-orn-7);
                                background-color: var(--color-bg-7);
                                padding: 1em;

                                >.title {
                                    >.borde {
                                        display: none;
                                    }

                                    >button {
                                        color: var(--color-txt-2);
                                    }
                                }

                                >.submenu {
                                    height: 0em;
                                    overflow: hidden;
                                    background-color: var(--color-bg-2);

                                    >.item-menu {
                                        >.submenu {
                                            >.item-menu:hover {
                                                background-color: var(--color-bg-15);
                                            }
                                        }
                                    }
                                }
                            }

                            >.item-menu.active {
                                >.title {
                                    >button {
                                        padding-bottom: 1em;
                                    }
                                }

                                >.submenu {
                                    height: 100%;
                                    padding: 1em;

                                    >.item-menu {
                                        >.submenu {
                                            padding: .5em 1em;

                                            >.item-menu {
                                                padding: .2em;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    >.container:has(.responsive-menu>.menu-open:checked) {
        >.menu {
            height: 100%;
        }
    }
}

@media (min-width: 769px) {
    header {
        border-bottom: solid 3px var(--color-orn-3);

        >.container {
            grid-template-columns: 35% 65%;
            padding: 0;

            >.logo {
                grid-column: 1;
                display: flex;
                align-items: center;
                transform: skewX(-34deg);
                border-right: solid 3px var(--color-orn-12);
                background-color: var(--color-bg-2);
                box-shadow: inset -3px 0px 5px -2px var(--color-shd-1);

                >a {
                    transform: skewX(34deg);
                    display: block;
                    padding-right: 2em;
                }
            }

            >.pesquisa {
                display: none;
            }

            >.responsive-menu {
                display: none;
            }

            >.menu button,
            >.menu a,
            >.menu p {
                color: var(--color-txt-2);
            }

            >.menu {
                grid-column: 2;
                grid-row: 1;
                display: grid;
                grid-template-columns: 70% 30%;
                width: 100%;
                height: 100%;
                overflow: visible;
                background-color: var(--color-bg-7);
                box-shadow: 30em 0px 0px var(--color-bg-7),
                    -10em 01px 0px var(--color-bg-7);

                >.links {
                    grid-column: 1;
                    grid-row: 1;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    border: solid 3px var(--color-orn-4);
                    border-right: solid 1px var(--color-orn-1);
                    background-color: var(--color-bg-8);
                    box-shadow: 40em 0px 0px var(--color-bg-8);
                    padding: .6em;

                    >nav>.menu {
                        display: flex;
                        justify-content: flex-end;
                        flex-wrap: wrap;
                        list-style: none;
                        margin: 0;
                        padding: 0;

                        >.item-menu {
                            padding: 0px 8px;
                            max-width: 200px;
                            font-size: var(--font-txt-6);
                            position: relative;

                            >.title {
                                text-decoration: none;
                                margin: 0;
                            }

                            >.submenu {
                                display: none;
                                flex-direction: column;
                                list-style: none;
                                margin: 0;
                                padding: 0;
                                position: absolute;
                                right: 0;
                                width: max-content;
                                padding: 1em;
                                gap: .5em;
                                border-radius: .5em;
                                border: solid 1px var(--color-orn-8);
                                background-color: var(--color-bg-14);

                                >.item-menu {
                                    >.title {
                                        text-decoration: none;
                                        margin: 0;
                                    }
                                }
                            }
                        }

                        >.item-menu:not(:first-child) {
                            border-left: 1px solid var(--color-orn-1);
                        }

                        >.item-menu:has(>.submenu) {
                            padding-right: 2em;
                        }

                        >.item-menu:has(>.submenu)::before {
                            content: '';
                            width: 0;
                            height: 0;
                            right: 8px;
                            top: 4px;
                            position: absolute;
                            border-style: solid;
                            border-width: 5px 8.7px 5px 0;
                            border-color: transparent var(--color-orn-7) transparent transparent;
                        }

                        >.item-menu:hover {
                            >.submenu {
                                display: flex;

                                >.item-menu:hover {
                                    border-bottom: solid 1px var(--color-orn-7);
                                }
                            }
                        }

                        >.item-menu:hover::before {
                            border-width: 8.7px 5px 0 5px;
                            border-color: var(--color-orn-7) transparent transparent transparent;
                        }
                    }
                }

                >.acessibilidade {
                    grid-row: 1;
                    grid-column: 2;
                    display: inline-flex;
                    justify-content: center;
                    background-color: transparent;
                    gap: 0;
                    padding: 0;
                    box-shadow: 9em 0px 0px var(--color-bg-8);
                    border-color: var(--color-orn-4);
                    background-color: var(--color-bg-8);

                    >button {
                        >i {
                            background-image: var(--img-contrates);
                        }
                    }
                }

                >nav {
                    grid-column: 1/3;
                    grid-row: 2;
                    display: flex;
                    justify-content: flex-end;
                    background-color: var(--color-bg-7);
                    box-shadow: 30em 0px 0px var(--color-bg-7);

                    >.menu {
                        display: flex;
                        width: max-content;
                        height: 100%;

                        >.item-menu {
                            display: flex;
                            cursor: pointer;
                            align-items: center;
                            justify-content: center;
                            width: 12em;
                            height: 6em;
                            padding: 0em 1.5em;
                            border-right: solid 3px var(--color-orn-3);

                            >.title {
                                >.borde {
                                    >.icon {
                                        display: none;
                                    }
                                }
                            }

                            >.submenu a,
                            >.submenu p,
                            >.submenu button {
                                color: var(--color-txt-1);
                            }

                            >.submenu {
                                position: absolute;
                                display: none;
                                flex-wrap: wrap;
                                top: 100%;
                                right: 0;
                                left: 0;
                                gap: 2em;
                                padding: 2em;
                                background-color: var(--color-bg-2);
                                border-top: solid 8px var(--color-orn-3);
                                box-shadow: 3px 3px 6px var(--color-bg-14);

                                >.item-menu {
                                    flex-grow: 1;
                                    padding-left: 10px;
                                    min-width: 400px;
                                    max-width: 400px;

                                    >.title {
                                        margin-bottom: 6px;
                                        border-bottom: 1px solid var(--color-orn-3);

                                        >button {
                                            margin: 0;
                                            border: none;
                                            background-color: transparent;
                                            font-weight: bolder;
                                        }

                                        >p {
                                            padding-bottom: 8px;
                                            margin: 0;
                                            border-bottom: 1.5px solid var(--color-orn-4);
                                            width: 70%;
                                            transition: all .5s ease;
                                        }

                                        >a {
                                            >p {
                                                margin: 0;
                                                width: 70%;
                                                padding-bottom: 8px;
                                                transition: all .5s ease;
                                            }
                                        }
                                    }

                                    .submenu {
                                        display: flex;
                                        flex-direction: column;
                                        list-style: none;
                                        padding: 0;
                                        margin: 0;

                                        >.item-menu {
                                            padding: 4px 8px;
                                        }

                                        >.item-menu:hover {

                                            background-color: var(--color-bg-4);
                                        }

                                        >.item-menu:not(:last-child) {
                                            border-bottom: solid 1px var(--color-orn-1);
                                        }
                                    }
                                }

                                >.item-menu:hover {
                                    >.title {
                                        >p {
                                            width: 80%;
                                        }

                                        >a {
                                            >p {
                                                width: 80%;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        >.item-menu.active {
                            >.submenu {
                                display: flex;
                            }
                        }

                        >.item-menu.active.overflow {
                            >.submenu {
                                display: none;
                            }
                        }
                    }

                    >.overflow-menu {
                        width: 0em;
                        overflow: hidden;
                        visibility: hidden;

                        >.item-menu {
                            height: 100%;
                            padding: 1em;
                            border-right: solid 3px var(--color-orn-3);

                            >.menu-open-button {
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                span,
                                span::before,
                                span::after {
                                    display: block;
                                    content: '';
                                    width: 2em;
                                    height: .3em;
                                    transition-duration: 180ms;
                                }

                                >span {
                                    display: flex;
                                    background-color: var(--color-orn-7);
                                }

                                >span::before {
                                    margin-top: -.5em;
                                    background-color: var(--color-orn-7);
                                }

                                >span::after {
                                    margin-top: .5em;
                                    background-color: var(--color-orn-7);
                                }
                            }

                            >.submenu button,
                            >.submenu p,
                            >.submenu a {
                                color: var(--color-txt-1);
                            }

                            >.submenu {
                                position: absolute;
                                height: 0%;
                                width: 0%;
                                overflow: hidden;
                                top: 100%;
                                right: 0;
                                transition-duration: 180ms;

                                >.item-menu {
                                    border: solid 2px var(--color-orn-7);
                                }
                            }
                        }

                        >.item-menu:has(.menu-open:checked) {
                            >.menu-open-button {
                                >span {
                                    display: block;
                                    transform: rotate(45deg);
                                }

                                >span::after {
                                    margin-top: 0;
                                    background-color: transparent;
                                }

                                >span::before {
                                    margin-top: 0;
                                    transform: rotate(-90deg);
                                }
                            }

                            >.submenu {
                                height: max-content;
                                width: 100%;
                                padding: 1em;
                                border-top: solid 8px var(--color-orn-3);
                                box-shadow: 3px 3px 6px var(--color-bg-14);
                            }
                        }
                    }

                    >.overflow-menu:has(.item-menu .submenu li) {
                        width: max-content;
                        visibility: visible;
                    }
                }
            }
        }
    }
}

@media (min-width: 1024px) {
    header {
        >.container {
            grid-template-columns: 30% 70%;

            >.menu {
                grid-template-columns: 82% 18%;
            }
        }
    }
}