html {
  font-size: 14px;
}

:root {
  /* Midias */
  --img-pesquisa: url('/src/assets/icons/pesquisa1.png');
  --img-pesquisa1: url('/src/assets/icons/pesquisa.png');
  --img-services: url('/src/assets/icons/custon-services.png');
  --img-contrates: url('/src/assets/icons/contrast.png');
  --img-contrates1: url('/src/assets/icons/contrast1.png');
  --img-confiabilidade: url('/src/assets/icons/confiabilidade.png');
  --img-personalizado: url('/src/assets/icons/personalizado.png');
  --img-faleconosco: url('/src/assets/icons/faleconosco.png');
  --img-certidao: url('/src/assets//icons/certidaoNegativa.png');
  --img-diveport: url('/src/assets/icons/diveport.png');
  --img-netdiver: url('/src/assets/icons/netdiver.png');
  --img-thumbtack: url('/src/assets/icons/thumbtack.png');
  --icon-tag: url("./assets/icons/tag.png");
  --img-notas: url("./assets/icons/boardnotes.png");
  --img-painel: url("./assets/icons/paineis.png");
  --img-mappage: url("./assets/icons/siteMap.png");


  /* Cores da Pagina */
  /* Textos */
  --color-txt-1: #000000;
  --color-txt-2: #ffffff;
  --color-txt-3: #029B60;
  --color-txt-4: #0048AA;
  --color-txt-5: #6F6F6F;
  --color-txt-6: #1565c0;

  /* Ornamentação */
  --color-orn-0: #000000;
  --color-orn-1: #e1e1e1;
  --color-orn-2: #1565c0;
  --color-orn-3: #0048AA;
  --color-orn-4: #029B60;
  --color-orn-5: #FFC800;
  --color-orn-6: #b71c1c;
  --color-orn-7: #ffffff;
  --color-orn-8: rgba(62, 57, 107, .1);
  --color-orn-9: #6F6F6F;
  --color-orn-10: #fb8c00;
  --color-orn-11: #05830b;
  --color-orn-12: rgba(62, 57, 107, .25);
  --color-orn-13: #f2f2f2;

  /* Fundo */
  --color-bg-0: transparent;
  --color-bg-1: #000000;
  --color-bg-2: #ffffff;
  --color-bg-3: #f2f2f2;
  --color-bg-4: #e1e1e1;
  --color-bg-5: #6F6F6F;
  --color-bg-6: #1565c0;
  --color-bg-7: #0048AA;
  --color-bg-8: #029B60;
  --color-bg-9: #ffea00;
  --color-bg-10: #FFC800;
  --color-bg-11: #e6b400;
  --color-bg-12: #fb8c00;
  --color-bg-14: rgba(52, 58, 64, 0.95);
  --color-bg-15: rgba(0, 0, 0, 0.6);

  /* Sombras */
  --color-shd-1: rgba(62, 57, 107, .25);
  --color-shd-2: rgba(62, 57, 107, .06);
  --color-shd-3: #ffffff;
  --color-shd-4: rgba(52, 58, 64, 0.95);

  /* Fontes da Página */
  --font-pg-1: 2.15em;

  /* Fontes do Texto */
  --font-txt-1: 2.15em;
  --font-txt-2: 1.8em;
  --font-txt-3: 1.5em;
  --font-txt-4: 1.2em;
  --font-txt-5: 1.1em;
  --font-txt-6: .875em;
  --font-txt-7: .8em;

  /* Variaveis para Responsividade */
  --responsive-var: 0;
}

.dark-mode {
  /* Midias */
  --img-pesquisa: url('/src/assets/icons/pesquisa1-contrastMode.png');
  --img-pesquisa1: url('/src/assets/icons/pesquisa-contrastMode.png');
  --img-services: url('/src/assets/icons/custon-services-contrastMode.png');
  --img-contrates: url('/src/assets/icons/contrast-mode.png');
  --img-contrates1: url('/src/assets/icons/contrast-mode.png');
  --img-confiabilidade: url('/src/assets/icons/confiabilidade-contrastMode.png');
  --img-personalizado: url('/src/assets/icons/personalizado-contrastMode.png');
  --img-faleconosco: url('/src/assets/icons/faleconosco-contrastMode.png');
  --img-thumbtack: url('/src/assets/icons/thumbtack-contrastMode.png');
  --icon-tag: url("./assets/icons/tag-contrastMode.png");
  --img-notas: url("./assets/icons/boardnotes-contarstMode.png");
  --img-painel: url("./assets/icons/paineis-contrastMode.png");
  --img-mappage: url("./assets/icons/siteMap-contrastMode.png");

  /* Variaveis para Acessibilidade */
  /* Texto */
  --color-txt-1: #FAFF00;
  --color-txt-2: #FAFF00;
  --color-txt-3: #FAFF00;
  --color-txt-4: #FAFF00;
  --color-txt-5: #FAFF00;
  --color-txt-6: #FAFF00;

  /* Ornamentação */
  --color-orn-0: #ffffff;
  --color-orn-1: #ffffff;
  --color-orn-2: #ffffff;
  --color-orn-3: #ffffff;
  --color-orn-4: #ffffff;
  --color-orn-5: #ffffff;
  --color-orn-7: #ffffff;
  --color-orn-8: #ffffff;
  --color-orn-9: #ffffff;
  --color-orn-10: #ffffff;
  --color-orn-11: #ffffff;
  --color-orn-12: #ffffff;
  --color-orn-13: #ffffff;

  /* Fundo */
  --color-bg-0: transparent;
  --color-bg-1: #000000;
  --color-bg-2: #000000;
  --color-bg-3: #000000;
  --color-bg-4: #000000;
  --color-bg-5: #000000;
  --color-bg-6: #000000;
  --color-bg-7: #000000;
  --color-bg-8: #000000;
  --color-bg-9: #000000;
  --color-bg-10: #000000;
  --color-bg-11: #000000;
  --color-bg-12: #000000;
  --color-bg-13: #000000;
  --color-bg-14: #000000;

  /* Sombras*/
  --color-shd-1: transparent;
  --color-shd-2: transparent;
  --color-shd-3: transparent;
}

*:focus-visible {
  z-index: 1000;
  outline: dashed 3px var(--color-orn-6) !important;
}

.App {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  background-color: var(--color-bg-2);

}

.telefone {
  width: 10px;
  height: 10px;
}

/* Barra de navegação das paginas */
.navpage {
  padding: 32px 0px;
  background-color: var(--color-bg-4);
  border-bottom: solid 2px var(--color-orn-1);

  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .title {
      font-size: var(--font-txt-2);
      font-weight: bold;
    }

    .nav {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 4px;

      .menu {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;

        .item-menu {
          padding: 0px 8px;

          >a {
            text-decoration: none;
            color: var(--color-txt-1);
          }
        }

        .item-menu p {
          margin: 0;
        }

        .item-menu:first-child {
          border-right: solid 1px var(--color-orn-3);
        }
      }
    }

    .description {
      padding-top: 16px;

      >p {
        margin: 0;
      }
    }
  }

  .container:first-child {
    border-bottom: solid 4px var(--color-orn-3);
  }

}

/*Carrosel de banners*/
.swiper {
  height: 46vh;

  .banner {
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    border-bottom: solid 4px var(--color-orn-7);

    >a {
      display: flex;
      width: 100%;
      margin-bottom: .5em;
      justify-content: center;
      text-decoration: none;

      >.painel {
        color: var(--color-txt-2);
        background-color: var(--color-bg-15);
        border-radius: 8px;
        border-bottom: none;
        border-left: none;

        .content {
          padding: 1em;

          >.title {
            font-weight: bold;
          }

          @media (max-width:1024px) {
            >.description {
              display: none;
            }

          }
        }
      }

    }
  }

  @media (max-width:768px) {
    height: 20vh;
  }

  .swiper-pagination-bullet {
    border: solid 2px var(--color-orn-8);
  }

  .swiper-pagination-bullet-active {
    background-color: var(--color-orn-7);
  }

  .swiper-button-prev,
  .swiper-button-next {
    margin: 0em 1.5em;
    color: var(--color-bg-2);
  }

  @media (max-width: 768px) {

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
}

/* Icones */
.icon {
  display: none;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.icon-card {
  padding: 12px;

  >.icon {
    width: 100%;
    height: 100%;
  }
}

.icon.endereco {
  background-image: url('/src/assets/icons/localizacao.png');
}

.icon.contato {
  background-image: url('/src/assets/icons/contato.png');
}

.icon.pdf {
  background-image: url('/src/assets/icons/002-pdf.png');
}

.icon.xls {
  background-image: url('/src/assets/icons/010-xls.png');
}

.icon.xlsx {
  background-image: url('/src/assets/icons/019-xlsx.png');
}