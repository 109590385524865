.workspace {
    .container.posts {
        .post>table {
            width: 100%;

            >thead {
                >tr {
                    >.title-column {
                        width: 70%;
                        text-align: start;
                    }

                    >th {

                        padding: 16px 8px;
                        border-bottom: solid 3px var(--color-orn-1);
                    }

                    >th:not(:nth-child(2)) {
                        text-align: center;
                    }
                }
            }

            >tbody {
                >tr {
                    >td {
                        padding: 16px 8px;
                        color: var(--color-txt-1);
                        border-bottom: solid 2px var(--color-orn-1);

                        >.icon {
                            display: block;
                            width: 36px;
                            height: 36px;
                        }
                    }

                    >td:not(:nth-child(2)) {
                        text-align: center;
                    }
                }

                >tr:hover {
                    background-color: var(--color-orn-1);
                }
            }
        }
    }
}

@media (min-width: 769px) {}

@media (min-width: 1200px) {}