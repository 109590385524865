.contatos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .transparencia,
    .ouvidoria {
        padding: 32px 16px;
    }

    .card {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;
        border: none;
        border-radius: 0;
        color: var(--font-txt-1);
        background-color: var(--color-bg-4);
        border: solid 2px var(--color-orn-1);
        text-align: center;
    }

    .transparencia {
        >.card {
            >div:not(:first-child) {
                border-top: solid 1px var(--color-orn-7);
                padding: 16px;
            }

            >.form {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 16px;

                >.title {
                    font-size: var(--font-txt-2);
                }

            }

            >.time {
                >.content {
                    font-weight: bold;
                    color: var(--color-txt-4);

                    >p {
                        margin: 0;
                    }
                }
            }

            >.address {
                >.title {
                    font-size: var(--font-txt-3);
                }
            }

            >.contact {
                >.title {
                    font-size: var(--font-txt-3);
                }
            }
        }
    }

    .ouvidoria {
        >.card {
            >div:not(:first-child) {
                border-top: solid 1px var(--color-orn-7);
                padding: 16px;
            }

            >.form {
                >.title {
                    font-size: var(--font-txt-2);
                }
            }

            >.time {
                >.content {
                    font-weight: bold;
                    color: var(--color-txt-4);
                }
            }

            >.address {
                >.title {
                    font-size: var(--font-txt-3);
                }
            }

            >.contact {
                >.title {
                    font-size: var(--font-txt-3);
                }
            }
        }
    }
}