.noticeboard {
    padding: 5em 0em;
    border-top: solid 4px var(--color-orn-3);
    background-color: var(--color-bg-7);
    display: flex;
    flex-direction: column;
    gap: 2em;
    align-items: center;

    >.figure {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: .5em;

        >.borde {
            >.icon {
                display: block;
                width: 5em;
                height: 5em;
                background-image: var(--img-notas);
            }
        }


        >h2 {
            font-weight: bold;
            color: var(--color-txt-2);
        }
    }

    >.board {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        >nav {
            padding: 2em;
            width: 100%;

            >.menu {
                list-style: none;
                display: flex;
                gap: 1em;
                padding: 0;
                margin: 0;
                justify-content: center;
                align-items: center;

                >.item-menu {
                    >.item {
                        position: relative;

                        >button {
                            border: none;
                            background-color: var(--color-bg-2);
                            color: var(--color-txt-4);
                            font-weight: bold;
                            font-size: var(--font-txt-5);
                            padding: .5em 1em;
                            border-radius: 6px;
                            box-shadow: inset 3px 3px 5px var(--color-shd-1);
                            border: solid 2px var(--color-orn-3);
                        }
                    }

                    >.item.active::after {
                        content: '';
                        width: 0;
                        height: 0;
                        right: 40%;
                        top: 110%;
                        position: absolute;
                        border-style: solid;
                        border-width: 1em 1em 1em 1em;
                        border-color: var(--color-orn-7) transparent transparent transparent;
                    }
                }
            }
        }

        >.cards-board {
            padding-bottom: 2em;
            overflow-y: auto;
            height: 100%;

            >.cards-borde {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0em 1em;

                >a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    padding: 1em 0em;

                    >.card {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 1em;
                        padding: 1em;
                        width: 100%;
                        max-width: 300px;
                        color: var(--color-txt-4);
                        background-color: var(--color-bg-2);
                        box-shadow: inset 3px 3px 5px var(--color-shd-1);
                        border: solid 2px var(--color-orn-3);
                        border-radius: 6px;

                        >.borde {
                            >.icon {
                                display: block;
                                width: 2em;
                                height: 2em;
                                background-image: var(--img-thumbtack);
                            }
                        }

                        >.title {
                            font-size: var(--font-txt-5);
                            font-weight: bold;
                            font-size: var(--font-txt-5);
                        }

                        >.content {
                            >p {
                                margin: 0;
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }

        >.cards-board::-webkit-scrollbar {
            width: .5em;
        }

        >.cards-board::-webkit-scrollbar-thumb {
            border-radius: 8em;
            background-color: var(--color-orn-7);
        }

        >.cards-board::-webkit-scrollbar-track {
            background-color: var(--color-bg-7);
            border-radius: 8em;
            border-top: solid 3px var(--color-orn-7);
            border-bottom: solid 3px var(--color-orn-7);
        }
    }
}

@media (min-width: 769px) {
    .noticeboard {
        >.board {
            height: 50vh;

            >nav {
                padding: 2em;

                >.menu {
                    >.item-menu {
                        >.item.active::after {
                            left: 100%;
                            top: 22%;
                            position: absolute;
                            border-style: solid;
                            border-width: .75em .75em .75em .75em;
                            border-color: transparent transparent transparent var(--color-orn-7);
                        }
                    }
                }
            }

            >.cards-board {
                >.cards-borde {
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: flex-start;

                    >a {
                        padding: 1em 1em;

                        >.card {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 2em;
                            height: 20em;
                            max-width: 100%;

                            >.borde {
                                >.icon {
                                    width: 2.5em;
                                    height: 2.5em;
                                }
                            }

                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 769px) {
    .noticeboard {
        >.board {
            flex-direction: row;

            >nav {
                width: max-content;
                height: 100%;
                flex-grow: 1;
                border-right: solid 1px var(--color-orn-1);

                >.menu {
                    flex-direction: column;
                    height: 100%;

                    >.item-menu {
                        >.item.active::after {
                            left: 105%;
                        }
                    }
                }
            }
        }

    }
}