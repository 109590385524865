.workspace {
    >.paineis {}

    >.services {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3em;
        padding: 3em 0em;
        padding-bottom: 6em;
        /*background-color: var(--color-bg-4);*/

        >.figure {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: .5em;

            >.borde {
                >.icon {
                    display: block;
                    width: 5em;
                    height: 5em;
                    background-image: var(--img-painel);
                }
            }


            >h2 {
                font-weight: bold;
                color: var(--color-txt-3);
            }
        }

        >.container {

            >nav ul {
                list-style: none;
                padding: 0;
                margin: 0;
            }

            >nav {
                >.menu {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    padding: 0em 2em;
                    gap: 1em;

                    >.item-menu button,
                    >.item-menu a {
                        text-decoration: none;
                        border: none;
                        background-color: transparent;
                        color: var(--color-txt-2);
                    }

                    >.item-menu {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 1em;
                        padding: 3em 1em;
                        padding-bottom: 1em;
                        width: 100%;
                        flex-grow: 1;
                        height: max-content;
                        max-width: 300px;
                        border-radius: 4px;
                        border: solid 2px var(--color-orn-4);
                        background-color: var(--color-bg-8);
                        box-shadow: 3px 3px 5px var(--color-shd-1);

                        >.title {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            gap: 1.5em;
                            width: 100%;

                            >.borde {
                                width: 100%;
                                display: flex;
                                justify-content: center;

                                >.icon {
                                    display: block;
                                    width: 5em;
                                    height: 5em;
                                }
                            }

                            >button {
                                display: block;
                                font-size: var(--font-txt-5);
                                font-weight: bold;
                                min-width: max-content;
                                width: 60%;
                                text-align: start;
                                border-bottom: solid 4px var(--color-orn-7);
                                transition-duration: 180ms;
                            }
                        }

                        >.submenu {
                            display: none;
                            width: 100%;
                            flex-direction: column;
                            padding: .5em 1em;

                            >.item-menu {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;

                                >.title {
                                    width: max-content;
                                }
                            }

                            >.item-menu:hover {
                                border-bottom: solid 1px var(--color-orn-7);

                                >.title {
                                    >a {
                                        display: block;
                                        font-weight: bold;
                                    }
                                }
                            }
                        }
                    }

                    >.item-menu:hover {
                        >.title {
                            >button {
                                width: 100%;
                            }
                        }
                    }

                    >.item-menu.active {
                        >.title {
                            >button {
                                width: 100%;
                            }
                        }

                        >.submenu {
                            display: flex;
                            flex-direction: column;
                            gap: .5em;
                        }
                    }

                    >.item-menu:nth-child(1) {
                        >.title {
                            >.borde {
                                >.icon {
                                    background-image: url('/src/assets/icons/service-orçamento.svg');
                                }
                            }
                        }
                    }

                    >.item-menu:nth-child(2) {
                        >.title {
                            >.borde {
                                >.icon {
                                    background-image: url('/src/assets/icons/service-fiscais.svg');
                                }
                            }
                        }
                    }

                    >.item-menu:nth-child(3) {
                        >.title {
                            >.borde {
                                >.icon {
                                    background-image: url('/src/assets/icons/service-infraestrutura.svg');
                                }
                            }
                        }
                    }


                }
            }
        }
    }

    >.sobre {
        border-top: solid 4px var(--color-orn-13);
        background-color: var(--color-bg-3);
        padding: 8em 0em;

        >.container {
            display: flex;
            flex-wrap: wrap;
            padding: 2em 1em;
            justify-content: center;

            >.card {
                border: none;
                background-color: transparent;
                padding: .5em;
                color: var(--color-txt-1);

                >.borde {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    border: solid 2px var(--color-orn-7);
                    background-color: var(--color-bg-2);
                    padding: 1.5em;
                    gap: 1em;
                    flex: 1;

                    >.icons {
                        >.icon {
                            display: block;
                            width: 4em;
                            height: 5em;
                        }

                        >.icon.confiabilidade {
                            background-image: var(--img-confiabilidade);
                        }

                        >.icon.consultas {
                            background-image: var(--img-personalizado);
                        }

                        >.icon.faleconosco {
                            background-image: var(--img-faleconosco);
                        }
                    }

                    >.title {
                        >h3 {
                            font-weight: bold;
                            text-align: center;
                        }
                    }

                    >.content {
                        text-align: justify;
                    }

                    >.guid {
                        >a {
                            text-decoration: none;
                            padding: 8px 16px;
                            border: solid 3px var(--color-orn-7);
                            border-radius: 4px;
                            color: var(--color-txt-2);
                            background-color: var(--color-bg-7);
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 769px) {
    .workspace {
        >.services {
            >.container {
                >nav {
                    >.menu {
                        >.item-menu {
                            cursor: pointer;
                            width: 45%;

                            >.title {
                                >.borde {
                                    >.icon {
                                        width: 6em;
                                        height: 6em;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        >.sobre {
            >.container {
                >.card {
                    padding: 1em;
                }
            }
        }
    }
}

@media (min-width: 1024px) {
    .workspace {
        >.services {
            >.container {
                >nav {
                    >.menu {
                        justify-content: center;

                        >.item-menu {
                            width: 30%;
                            max-width: 350px;
                        }
                    }
                }
            }
        }

        >.sobre {
            >.container {
                >.card {
                    padding: 2em;
                }
            }
        }
    }
}