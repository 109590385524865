.body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 80vh;
    padding: 0;

    >.demonstativos {
        >.painel {
            height: max-content;

            >iframe {
                width: 100%;
                height: 3000px;
            }
        }
    }

    >.auxiliary-bar {
        display: none;
    }
}

@media (min-width: 769px) {
    .body {
        >.auxiliary-bar {
            display: flex;
            flex-direction: column;
            gap: 2em;
            width: 0;
            height: 0;
            justify-content: flex-end;
            align-items: center;
            position: sticky;
            left: 95%;
            bottom: 0em;
            z-index: 100;

            .icon {
                display: block;
                width: 3em;
                height: 3em;
            }

            .tooltiptext {
                visibility: hidden;
                width: 120px;
                background-color: black;
                color: white;
                text-align: center;
                border-radius: 6px;
                padding: 5px 0;

                /* Posicionamento */
                position: absolute;
                z-index: 1;
                top: -5px;
                left: 105%;
            }

            .menu-item:hover .tooltiptext {
                visibility: visible;
            }

            >.pesquisa {
                margin-bottom: 4em;

                >a {
                    display: block;
                    width: max-content;
                    height: max-content;
                    padding: 1em;
                    border-radius: 100%;
                    border: solid 2px var(--color-orn-3);
                    background-color: var(--color-bg-7);
                    box-shadow: 2px 2px 4px var(--color-bg-15);

                    >p {
                        display: none;
                    }

                    .icon:hover {
                        display: block;
                        width: 3.5em;
                        height: 3.5em;
                    }

                    .icon.pesquisa {
                        background-image: var(--img-pesquisa);
                    }
                }
            }

            >.services {
                display: flex;
                align-items: flex-end;

                >nav {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 2em;
                    height: 5.3em;
                    background-color: var(--color-bg-7);
                    box-shadow: 2px 2px 4px var(--color-bg-15);
                    transition-duration: 180ms;
                    border-radius: 10em;
                    overflow: hidden;
                    border: solid 2px var(--color-orn-3);

                    >.menu-open {
                        display: none;
                    }

                    >.menu-open-button {
                        z-index: 9;
                        cursor: pointer;
                        padding: 1em;

                        >.icon.services {
                            background-image: var(--img-services);
                        }
                    }

                    >.menu-item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        gap: 6px;
                        color: var(--color-txt-2);
                        text-decoration: none;

                        >p {
                            text-align: center;
                            margin: 0;
                            font-weight: bold;
                        }

                        >.icon.certidao {
                            background-image: var(--img-certidao);
                        }

                        >.icon.netdiver {
                            background-image: var(--img-netdiver);
                        }

                        >.icon.diveport {
                            background-image: var(--img-diveport);
                        }
                    }
                }

                >nav:has(.menu-open:checked) {
                    height: 100%;
                    padding: 2em 1em;
                }
            }
        }
    }
}