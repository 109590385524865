.glossario-page {
    >.container {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;
        padding: 16px 0px;

        >.glossario-filtro {
            background-color: var(--color-bg-4);

            height: max-content;
            padding: 16px;

            >.title {
                font-size: var(--font-txt-3);
                font-weight: bold;
            }

            >input {
                border-radius: 8px;
                border: solid 1px var(--color-bg-3);
                padding: 8px;
                width: 100%;
            }
        }

        >.glossario {
            >ul {
                list-style: none;
                display: flex;
                flex-direction: column;
                padding: 0px 16px;
                gap: 24px;

                >li {
                    border-bottom: solid 1px var(--color-orn-1);

                    .title {
                        font-size: var(--font-txt-4);
                        font-weight: bold;

                        >p {
                            margin-bottom: 4px;
                        }
                    }

                    .content {
                        >p {
                            margin-bottom: 4px;
                        }
                    }
                }
            }
        }
    }
}