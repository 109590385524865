.workspace {
    >.container.posts {
        justify-content: center;

        >.post {
            >.ck.ck-editor__main a {
                text-decoration: none;
            }

            >.ck.ck-editor__main a u {
                text-decoration: none;
            }

            >.ck.ck-editor__main {
                .teste {
                    >.teste_title {}

                    >.teste_options {}
                }
            }
        }
    }
}

@media (min-width: 769px) {
    .workspace {
        >.container.posts {
            justify-content: space-between;
        }
    }
}