.workspace {
    >.search.container {
        >.search.form {
            padding: 32px 0px;

            >form {
                display: flex;
                padding: 16px;
                gap: 10px;
                background-color: var(--color-bg-4);
                border: solid 1px var(--color-orn-1);
                box-shadow: 2px 2px 5px var(--color-shd-1);

                >input {
                    border-radius: 5px;
                    border: solid 1px var(--color-orn-1);
                    padding: 8px;
                    width: 100%;
                }

                >button {
                    border: none;
                    height: max-content;
                    width: max-content;
                    padding: 8px 24px;
                    text-decoration: none;
                    font-weight: bold;
                    color: var(--color-txt-2);
                    border-radius: 4px;
                    border: solid 1px var(--color-orn-4);
                    background-color: var(--color-bg-8);
                }

                >button:hover {
                    border-radius: 4px;
                    color: var(--color-txt-1);
                    border: solid 1px var(--color-orn-5);
                    background-color: var(--color-bg-10);
                }
            }
        }

        >.search.results {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 32px 0px;

            >p {
                font-weight: bold;
                font-size: larger;
                text-align: center;
            }

            >.search {

                >.title {
                    width: 100%;
                    font-size: x-large;

                    >p {
                        display: flex;
                        gap: 8px;
                        width: 100%;
                    }
                }

                >ul {
                    display: flex;
                    flex-direction: column;
                    list-style: none;
                    padding: 0;
                    gap: 16px;

                    >li {
                        padding: 16px;
                        border: solid 1px var(--color-orn-1);
                        margin-bottom: 16px;
                        background-color: var(--color-bg-4);
                        border-left: solid 5px var(--color-orn-10);
                        box-shadow: 2px 2px 5px var(--color-shd-1);

                        >a {
                            text-decoration: none;
                            color: var(--color-txt-1);

                            >.info {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                font-size: smaller;
                                color: var(--color-txt-1);
                                gap: 16px;

                                >.title {
                                    width: 80%;
                                    font-weight: bold;
                                    font-size: var(--font-txt-4);
                                }

                                >.category {
                                    display: flex;
                                    gap: 10px;

                                    >a {
                                        margin: 0;
                                        color: var(--color-txt-1);
                                    }
                                }

                                >.date {
                                    max-width: max-content;
                                }
                            }

                            >.content {
                                padding-top: 8px;
                            }
                        }
                    }
                }
            }

        }
    }
}

@media (max-width: 768px) {
    .workspace {
        >.search.container {
            >.search.results {
                >.search {
                    >ul {
                        >li {
                            border-left: solid 5px var(--color-orn-10);

                            >.info {
                                display: grid;
                                grid-gap: 0em;
                                grid-template-columns: 50% auto;
                                grid-template-rows: inherit;

                                >.title {
                                    grid-column: 1/3;
                                    grid-row: 1;
                                }

                                >.category {
                                    grid-column: 1;
                                    grid-row: 2;
                                }

                                >.date {
                                    width: 100%;
                                    grid-column: 3;
                                    grid-row: 2;
                                }
                            }

                            >.content {
                                padding-top: 2em;
                            }
                        }
                    }
                }
            }
        }
    }
}