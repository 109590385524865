.workspace {
    >.categories.container {
        >.results {
            padding: 32px 0px;

            >ul {
                display: flex;
                flex-direction: column;
                list-style: none;
                padding: 0;
                gap: 16px;

                >li {
                    padding: 16px;
                    border: solid 1px var(--color-orn-1);
                    margin-bottom: 16px;
                    background-color: var(--color-bg-4);
                    border-left: solid 5px var(--color-orn-10);
                    box-shadow: 2px 2px 5px var(--color-shd-1);

                    >a {
                        text-decoration: none;
                        color: var(--color-txt-1);

                        >.info {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: smaller;
                            color: var(--color-txt-1);
                            gap: 16px;

                            >.title {
                                width: 80%;
                                font-weight: bold;
                                font-size: var(--font-txt-4);
                            }

                            >.category {
                                display: flex;
                                gap: 10px;

                                >a {
                                    margin: 0;
                                    color: var(--color-txt-1);
                                }
                            }

                            >.date {
                                max-width: max-content;
                            }
                        }

                        >.content {
                            padding-top: 8px;
                        }
                    }
                }
            }
        }
    }
}