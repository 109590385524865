.body {
    >.container.erro {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60vh;
        gap: 5em;

        >img {
            width: 10vw;
        }

        >.description {
            >h1 {
                font-weight: bold;
            }
        }
    }
}