@media (max-width: 768px) {
    .workspace {
        >.container.posts {
            display: flex;
            justify-content: space-between;
            margin-top: 6vh;
            margin-bottom: 6vh;
            position: relative;
            overflow: hidden;

            >.post {
                min-height: 60vh;

                >.info {
                    display: flex;
                    justify-content: space-between;
                    padding: 16px 0px;
                    color: var(--color-txt-5);

                    >.categories {
                        >ul {
                            display: flex;
                            flex-wrap: wrap;
                            margin: 0;
                            padding: 0;
                            list-style: none;

                            >li {
                                padding: 0px 16px;
                                border-left: solid 1px var(--color-orn-9);

                                >p {
                                    margin: 0;
                                }
                            }

                            >li:first-child {
                                border: none;
                                list-style-image: var(--icon-tag);
                                list-style-position: outside;
                                padding-left: 0;
                            }
                        }
                    }
                }
            }

            >.sidebar {
                display: none;
            }
        }
    }
}

@media (min-width: 769px) {
    .workspace {
        >.container.posts {
            display: flex;
            justify-content: space-between;
            margin-bottom: 6vh;
            position: relative;
            overflow: hidden;

            >.post {
                min-height: 60vh;

                >.info {
                    display: flex;
                    justify-content: space-between;
                    padding: 16px 0px;
                    color: var(--color-txt-5);

                    >.categories {
                        >ul {
                            display: flex;
                            flex-wrap: wrap;
                            margin: 0;
                            padding: 0;
                            list-style: none;

                            >li {
                                padding: 0px 16px;
                                border-left: solid 1px var(--color-orn-9);

                                >p {
                                    margin: 0;
                                }
                            }

                            >li:first-child {
                                border: none;
                                list-style-image: var(--icon-tag);
                                list-style-position: outside;
                                padding-left: 0;
                            }
                        }
                    }
                }
            }

            >.sidebar {
                display: flex;
                flex-direction: column;
                gap: 24px;

                >.categories {
                    display: flex;
                    gap: 32px;
                    flex-direction: column;

                    >.title {
                        width: 100%;
                        border-bottom: solid 3px var(--color-orn-1);
                        padding: 4px 8px;
                        position: relative;

                        >p {
                            font-size: var(--font-txt-4);
                            font-weight: bold;
                            margin: 0;
                        }

                        ::before {
                            display: block;
                            width: 30%;
                            height: 2px;
                            background-color: var(--color-orn-5);
                            content: " ";
                            position: absolute;
                            top: 100%;
                            left: 0;
                        }
                    }

                    >ul {
                        display: flex;
                        flex-direction: column;
                        gap: 16px;
                        list-style: none;
                        width: max-content;
                        padding: 0px;
                        width: 100%;

                        >li {
                            width: 100%;
                            padding: 0px 8px;
                            border-bottom: solid 1px var(--color-orn-1);

                            >a {
                                color: var(--color-txt-1);
                                text-decoration: none;
                            }
                        }
                    }
                }

                >.related {
                    display: flex;
                    flex-direction: column;
                    gap: 32px;

                    >.title {
                        width: 100%;
                        border-bottom: solid 3px var(--color-orn-1);
                        padding: 4px 8px;
                        position: relative;

                        >p {

                            font-size: var(--font-txt-4);
                            font-weight: bold;
                            margin: 0;
                        }

                        ::before {
                            display: block;
                            width: 30%;
                            height: 2px;
                            background-color: var(--color-orn-5);
                            content: " ";
                            position: absolute;
                            top: 100%;
                            left: 0;
                        }
                    }

                    >.cards {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        >.borde {
                            text-decoration: none;

                            >.card {
                                border: none;
                                background-color: var(--color-bg-0);
                                border-bottom: solid 1px var(--color-orn-1);

                                >.info {
                                    display: flex;
                                    justify-content: space-between;
                                    font-size: var(--font-txt-7);
                                    color: var(--color-txt-5);

                                    >.categories {
                                        >ul {
                                            display: flex;
                                            flex-wrap: wrap;
                                            list-style-image: var(--icon-tag);
                                            margin: 0;
                                        }
                                    }
                                }

                                >.content {
                                    display: flex;
                                    flex-direction: column;
                                    color: var(--color-txt-1);

                                    >.title {
                                        font-weight: bolder;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        >.container iframe {
            top: 0;
            left: 0;
            width: 100%;
        }
    }
}

@media (min-width: 1200px) {}