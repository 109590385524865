@media (max-width: 768px) {
  footer {

    .mapa,
    .copyright,
    .info {
      box-shadow: 0px 0px 1.5px rgb(0, 0, 0.1);
    }

    .copyright,
    .info {
      border-top: solid 8px var(--color-orn-7);
    }

    >.mapa {
      padding: 50px 0px;
      background-color: var(--color-bg-4);
      border-top: solid 4px var(--color-orn-1);

      >.container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 3em;

        >.figure {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: .5em;

          >.borde {
            >.icon {
              display: block;
              width: 5em;
              height: 5em;
              background-image: var(--img-notas);
            }
          }


          >h2 {
            font-weight: bold;
            color: var(--color-txt-3);
          }
        }

        >.menu {
          list-style: none;
          padding: 16px;

          >.item-menu {
            >.title {
              color: var(--color-txt-3);
              font-weight: bold;
              border-bottom: solid 1px var(--color-orn-4);
              padding: 8px;

              >p {
                margin: 0;
              }
            }

            >.submenu {
              display: flex;
              flex-direction: column;
              gap: 8px;
              list-style: none;
              padding: 8px;

              >.item-menu {
                >.title {
                  font-weight: bold;

                  >p {
                    margin: 0;
                  }
                }

                >a {
                  text-decoration: none;
                  color: var(--color-txt-1);
                }

                >.submenu {
                  list-style: none;
                  padding: 8px;

                  >.item-menu {
                    >.title {
                      color: var(--color-txt-3);
                      font-weight: bold;
                      border-bottom: solid 1px var(--color-orn-4);
                      padding: 8px;

                      >p {
                        margin: 0;
                      }
                    }

                    >a {
                      text-decoration: none;
                      color: var(--color-txt-1);
                    }
                  }
                }
              }
            }
          }
        }

        >nav ul,
        button,
        a {
          list-style: none;
          border: none;
          background-color: var(--color-bg-0);
          text-decoration: none;
          color: var(--color-txt-1);
        }

        >nav {
          >.menu {
            padding: 16px;

            >.item-menu {
              >.title {
                border-bottom: solid 1px var(--color-orn-4);
                padding: 8px;

                >button {
                  color: var(--color-txt-3);
                  font-weight: bold;
                }
              }

              >.submenu {
                display: flex;
                flex-direction: column;
                padding: 8px;
                gap: 8px;

                >.item-menu:has(.submenu) {
                  >.title {
                    >button {
                      font-weight: bold;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    >.info {
      background-color: var(--color-bg-8);
      padding: 30px;

      >.maps {
        display: none;
      }

      >.container {
        color: var(--color-txt-2);

        >.content {
          display: flex;
          flex-direction: column;
          gap: 32px;

          >.logos {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;

            >img {
              width: 95px;
              height: 90px;
            }
          }

          >.content {
            .title {
              display: flex;
              gap: 16px;
              font-weight: bold;

              >.icon {
                display: block;
              }
            }

            .description {
              padding-left: 30px;
            }
          }
        }
      }
    }

    .copyright {
      padding: 50px;
      background-color: var(--color-bg-7);
    }
  }
}

@media (min-width: 769px) {
  footer {
    overflow: hidden;

    .mapa,
    .copyright,
    .info {
      box-shadow: 0px 0px 1.5px rgb(0, 0, 0.1);
    }

    >.mapa {
      min-height: 20vh;
      padding: 5em 0em;
      background-color: var(--color-bg-4);
      border-top: solid 3px var(--color-orn-1);

      >.container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 3em;

        >.figure {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: .5em;

          >.borde {
            >.icon {
              display: block;
              width: 5em;
              height: 5em;
              background-image: var(--img-mappage);
            }
          }


          >h2 {
            font-weight: bold;
            color: var(--color-txt-3);
          }
        }

        >nav ul,
        button,
        a {
          list-style: none;
          border: none;
          background-color: var(--color-bg-0);
          text-decoration: none;
          color: var(--color-txt-1);
          padding: 0;
        }

        >nav {
          display: flex;
          width: 100%;

          >.menu {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            >.item-menu {
              flex: 1 1;
              padding: 0px 16px;

              >.title {
                font-size: var(--font-txt-4);
                border-bottom: solid 1px var(--color-orn-4);
                padding: 8px 16px;

                >button {
                  color: var(--color-txt-3);
                  font-weight: bold;
                }
              }

              >.submenu {
                display: flex;
                flex-direction: column;
                padding: 8px;
                gap: 8px;

                >.item-menu:has(.submenu) {
                  >.title {
                    >button {
                      font-weight: bold;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    >.info {
      display: flex;
      position: relative;
      min-height: 10vh;
      background-color: var(--color-bg-8);
      border-top: solid 8px var(--color-orn-7);

      >.maps {
        display: none;
      }

      >.container {
        z-index: 10;
        padding: 0;

        >.content {
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--color-txt-2);
          padding: 2.5em 1em;
          gap: 4em;

          .logos>img {
            width: 12em;
          }

          >.content {
            max-width: 30em;
            font-size: medium;

            .title {
              font-weight: bold;
              font-size: larger;
              display: flex;
              align-items: center;
              gap: 10px;

              >.icon {
                display: block;
              }
            }

            .title p {
              margin: 0;
            }

            .description {
              padding-left: 20px;
            }
          }
        }
      }
    }

    >.copyright {
      min-height: 6vh;
      padding: 16px 0;
      background-color: var(--color-bg-7);
      border-top: solid 8px var(--color-orn-7);
    }
  }
}

@media (min-width: 1024px) {
  footer {
    >.info {
      >.maps {
        display: block;
        height: 100%;
        width: 32vw;
        position: absolute;
        right: 0;


        >a {
          >img {
            height: 100%;
            width: 32vw;
          }
        }
      }

      >.container {
        >.content {
          justify-content: flex-start;
          transform: skewX(34deg);
          background-color: var(--color-bg-8);
          width: 83%;
          gap: 2em;

          .logos>img {
            transform: skewX(-34deg);
          }

          >.content {
            transform: skewX(-34deg);
          }
        }
      }
    }
  }
}