.legislacao{
    >.container{
        padding: 0;
    }
}

.time-line {
    display: flex;
    flex-direction: column;
    padding: 32px 0px;
    margin: 0;

    .year {
        display: flex;
        list-style: none;
        align-items: center;

        .title {
            font-size: var(--font-txt-3);
            font-weight: bold;
        }

        .list{
            display: flex;
            flex-direction: column;
            padding: 0;
            gap: 16px;

            .marker {
                display: flex;
                flex-wrap: wrap;
                border-left: solid 1px var(--color-orn-1); 
    
                .title {
                    font-size: var(--font-txt-4);
                    font-weight: bold;
                }
    
                .description {
                    >p {
                        margin: 0;
                    }
                }
    
                .col-1 {
                    display: flex;
                    justify-content: center;
                    align-items: center;
    
                    .button {
                        height: max-content;
                        width: max-content;
                        padding: 8px 24px;
                        background-color: var(--color-bg-8);
                        text-decoration: none;
                        font-weight: bold;
                        color: var(--color-txt-2);
                        border: solid 2px var(--color-orn-4);
                    }
                }
            }
        }
    }

    

  @media (max-width:768px) {
    .year {
      flex-direction: column;
    }

  }
}